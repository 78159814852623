<template>
  <div>
    <v-card class="card-shadow">
      <v-img
        :src="`https://images.unsplash.com/photo-1539678786826-79e8b51b457a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="370px"
      >
        <v-card-title>testing</v-card-title>
      </v-img>

      <v-tabs v-model="tab1" centered>
        <v-tabs-slider></v-tabs-slider>

        <v-tab> Recents </v-tab>
        <v-tab> Recents </v-tab>
        <v-tab> Recents </v-tab>
        <v-tab> Recents </v-tab>
      </v-tabs>
    </v-card>
    <v-card class="card-shadow mt-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" lg="8">
            <v-card-title> braap </v-card-title>
            <v-card-text class="pb-0">
              n publishing and graphic design, lorem ipsum is placeholder text
              (filler text) commonly used to demonstrate the graphics elements
              of a document or visual presentation, such as font, typography,
              and layout. The lorem ipsum text is typically a section of a Latin
              text by Cicero with words altered, added and removed that make it
              nonsensical in meaning and not proper Latin.
            </v-card-text>
            <v-card-text>
              <v-btn color="primary" large class="mr-2">Get Started</v-btn>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" lg="4">
            <v-img
              :src="`https://images.unsplash.com/photo-1539678786826-79e8b51b457a`"
              class="white--text align-end"
              cover
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="card-shadow mt-10">
      <v-container>
        <v-row>
          <v-col>
            <v-card-title> braap </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs v-model="tab2" background-color="transparent">
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab2">
              <v-tab-item v-for="item in items" :key="item">
                <v-container style="min-height: 260px">
                  <v-row>
                    <v-col cols="12" md="12" lg="4">
                      <v-img
                        :src="`https://images.unsplash.com/photo-1539678786826-79e8b51b457a`"
                        class="white--text align-end"
                        cover
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="12" lg="8">
                      <v-card-text class="pb-0">
                        n publishing and graphic design, lorem ipsum is
                        placeholder text (filler text) commonly used to
                        demonstrate the graphics elements of a document or
                        visual presentation, such as font, typography, and
                        layout. The lorem ipsum text is typically a section of a
                        Latin text by Cicero with words altered, added and
                        removed that make it nonsensical in meaning and not
                        proper Latin.
                      </v-card-text>
                      <v-card-text>
                        <v-btn color="primary" large class="mr-2"
                          >Get Started</v-btn
                        >
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="card-shadow mt-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" lg="8">
            <v-card-title> braap </v-card-title>
            <v-card-text class="pb-0">
              n publishing and graphic design, lorem ipsum is placeholder text
              (filler text) commonly used to demonstrate the graphics elements
              of a document or visual presentation, such as font, typography,
              and layout. The lorem ipsum text is typically a section of a Latin
              text by Cicero with words altered, added and removed that make it
              nonsensical in meaning and not proper Latin.
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" lg="4">
            <v-img
              :src="`https://images.unsplash.com/photo-1539678786826-79e8b51b457a`"
              class="white--text align-end"
              cover
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="card-shadow mt-10">
      <v-container>
        <v-row>
          <v-col>
            <v-card-title> braap </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-carousel cycle continuous height="200">
              <v-carousel-item v-for="(color, i) in colors" :key="color">
                <v-sheet height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div class="text-h2">Slide {{ i + 1 }}</div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="card-shadow mt-10">
      <v-container>
        <v-row>
          <v-col>
            <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=22%20karee%20street&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "About",

  components: {},
  data: () => {
    return {
      tab1: 0,
      tab2: 0,
      items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
      colors: [
        "red",
        "green",
        "blue",
        "purple",
        "yellow",
        "pink",
        "orange",
        "amber",
      ],
    };
  },
};
</script>
